/** @jsx jsx */

import { FC } from 'react'
import { Box, jsx, Flex, Text, Image, Button } from 'theme-ui'

import styled from '@emotion/styled'
import { Link } from 'gatsby'

export interface CardProps {
  title: string
  description: string
  icon?: any
  variant?: any
}

const CardWrap = styled.div(`
    width: 100%;
    maxHeight: 391px;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    .cardOverlayFrame {

    }
    &:hover {
      .cardOverlay {
        display: inherit;
      }
      .cardOverlayFrame {
        top: 0;
      }
    }
`)

const BottomPlate = {}

/**
 * NFT Card v0.1
 * =============
 */
interface NFTCardItem {
  title?: string
  desc?: string
  price?: string
  image?: string
  coin_img?: string
  onShowCard: any
  card?: any
}

const stretchP = {
  left: 0,
  right: 0,
  // top: 0,
  bottom: 0,
}

const NFTCardItem: FC<NFTCardItem> = ({ card, title, desc, price, image, coin_img, onShowCard }) => (
  <CardWrap>
    <Box variant="box.cardFrame" className="cardOverlayFrame">
      {/* <Button>Open Card</Button> */}
      {/* <Flex>
        <Box sx={{ pl: 0 }}>
          <Text variant="cardTitle">{title}</Text>
          <Text variant="cardDesc">{desc}</Text>
        </Box>
      </Flex>
      <Box sx={{ display: 'none', pt: 3 }} className="cardOverlay">
        <Button variant="btnWidePrimary" onClick={onShowCard}>
          Stake
        </Button>
        <Button variant="btnWideSecond">Buy</Button>
        <Box as="p" sx={{ textAlign: 'center', pt: 3, fontSize: 1 }}>
          <Text as="span" sx={{ fontWeight: 100 }}>
            Needs
          </Text>{' '}
          {price} MATIC
        </Box>
      </Box> */}
    </Box>
    <Box sx={{ p: 0, position: 'relative' }}>
      <Box
        sx={{ bg: 'grays.1', height: '240px', overflow: 'hidden', borderBottom: 'solid 1px', borderColor: 'grays.2' }}
      >
        <Box
          onClick={() => onShowCard(card)}
          sx={{ textAlign: 'center', display: 'block', mx: 'auto', height: '100%' }}
        >
          <Image src={image} sx={{ height: '100%', width: 'auto' }} />
        </Box>
      </Box>
      <Box sx={{ bg: 'grays.2', minHeight: '200px' }}>
        <Flex variant="box.boxSectionHeading" sx={{ py: 0, pt: 3 }}>
          <Text as="h3" sx={{ lineHeight: 'heading', fontSize: 1, m: 0, color: 'grays.9', fontWeight: 900 }}>
            {title}
          </Text>
        </Flex>
        <Box variant="box.boxSectionHeading" sx={{ pt: 1 }}>
          <Box sx={{ maxHeight: '100px', overflow: 'hidden' }}>
            <Text as="p" color="grays.7" sx={{ fontSize: 0, lineHeight: 'body' }}>
              {desc.substring(0, 70)}...
            </Text>
          </Box>
          <Flex variant="box.no" sx={{ alignItems: 'flex-start', pt: 3, borderTop: 'solid 1px #444', mt: 3 }}>
            <Box sx={{ width: '50%', textAlign: 'left', pl: 1 }}>
              <Text as="h3" sx={{ fontSize: 1, fontWeight: 'bold', pl: 1, m: 0, p: 0, color: 'teal.0' }}>
                20,000
              </Text>
              <Text as="h4" sx={{ fontSize: 0, fontWeight: 'body', pl: 0, color: 'grays.6' }}>
                Points
              </Text>
            </Box>
            <Box sx={{ width: '50%', ml: 'auto', alignItems: 'flex-start', textAlign: 'right' }}>
              {/* <Image width="14px" src={coin_img} sx={{ mr: 1 }} /> */}
              <Text
                sx={{
                  fontSize: 1,
                  mt: 0,
                  mb: 0,
                }}
              >
                1
              </Text>
              <Text
                as="span"
                sx={{
                  // border: 'solid 1px',
                  // borderColor: 'grays.4',
                  borderRadius: 9,
                  // px: 2,
                  fontSize: 0,
                  // bg: 'grays.4',
                  pt: 0,
                  pb: 0,
                  pl: 2,
                  // color: 'grays.2',
                  textTransform: 'uppercase',
                  fontWeight: 400,
                  color: 'grays.6',
                }}
              >
                Available
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box sx={{ mt: 0, p: 3 }}>
          <Button sx={{ width: '100%', bg: 'grays.1', border: 'solid 2px', borderColor: 'grays.6', color: 'teal.0', borderRadius: 5 }}>Redeem</Button>
        </Box>
      </Box>
    </Box>
  </CardWrap>
)

export default NFTCardItem
