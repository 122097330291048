/** @jsx jsx */
import { FC } from 'react'
import { graphql } from 'gatsby'
import { Box, jsx, Text } from 'theme-ui'
import Halo from '../components/halo'
import Layout from '../components/layout'
import MatchDetailFrame from '../components/matchdetail'
import Navigation from '../components/navig'
import PageFrame from '../components/page'

interface MatchTemplateProps {
  data?: any
  errors?: any
}

export const query = graphql`
  query MatchDetailQuery($slug: String!) {
    matchesYaml(slug: { eq: $slug }) {
      name
      date
      duration
      id
      image
      match
      teama
      teama_logo
      teamb
      value
      teamb_logo
      venue
      slug
      coin
      desc
      time
      competitions {
        edition
      }
      cardImage
      cards {
        coin_img
        desc
        image
        price
        title
      }
    }
  }
`

const MatchTemplate: FC<MatchTemplateProps> = props => {
  const { data, errors } = props
  const matchDetail = data?.matchesYaml || null
  return (
    <Layout>
      <Navigation alt={false} />
      <Box variant="box.plateBig" sx={{ pb: 0, pt: 0 }}>
        <Halo title={`${matchDetail.name} | Functonary NFT`} url={`/match/${matchDetail.slug}`} />
      </Box>
      <MatchDetailFrame cards={matchDetail} image={matchDetail.image} />
    </Layout>
  )
}

export default MatchTemplate
