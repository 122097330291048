/** @jsx jsx */

import { FC } from 'react'
import { Box, jsx, Text } from 'theme-ui'

/**
 * Re-usable TextBlocks
 */

interface TextBlock {
  label: string
  value: string
  symbol: string
  variant?: string
}

const TextBlock: FC<TextBlock> = ({ value, symbol, label, variant }) => {
  return (
    <Box variant={variant ? 'box.textBlock' : variant}>
      <Text sx={{ color: 'grays.4', fontSize: 0, textTransform: 'uppercase', mb: 0 }}>{label}</Text>
      <Text>
        {value}
        {symbol && (
          <Text as="span" sx={{ fontSize: 0, opacity: 0.5, ml: 2, mt: '-2px' }}>
            {symbol}
          </Text>
        )}
      </Text>
    </Box>
  )
}

export default TextBlock
