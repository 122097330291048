/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, jsx, Text, Flex, Image, Button } from 'theme-ui'

import TextBlock from './textblock'

export interface MatchDetailFrameProps {
  card?: any
}

const CardDetailFrame = ({ card }: MatchDetailFrameProps) => {
  return (
    <Box sx={{ borderRadius: '1rem' }}>
      <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
        <Box sx={{ width: ['100%', '60%', '40%'], pl: 3, pt: 3 }}>
          <Box sx={{ bg: 'grays.4', height: '240px', overflow: 'hidden' }}>
            <Link to={`/cards/${card.id}`} sx={{ textAlign: 'center', display: 'block', mx: 'auto', height: '100%' }}>
              <Image src={card.image} sx={{ height: '100%', width: 'auto' }} />
            </Link>
          </Box>
          <Box>
            <Box sx={{ pt: 2}}>
              <Text as="h6" sx={{ py: 2}}>View On Opensea <Image sx={{ ml: 1, mt: '-3px', width: '12px', height: '12px', borderRadius: 8 }} src={'https://storage.googleapis.com/opensea-static/favicon/apple-touch-icon.png'}/></Text>
            </Box>

            <TextBlock variant="box.cardBlock" label="" value="10000" symbol="$RAGE" />
            <Box sx={{ mt: 1, py: 3 }}>
              <Button sx={{ width: '100%', borderRadius: 5 }}>Redeem</Button>
            </Box>
            <Flex variant="box.boxSectionHeading" sx={{ alignItems: 'center' }}>
              <Text as="h6" sx={{ fontSize: 0, color: 'grays.1', textTransform: 'uppercase', fontWeight: 900 }}>
                {card?.count > 0 && `${card.count} Available`}
                {card?.count <= 1 && `1 available`}
              </Text>
            </Flex>
          </Box>
        </Box>
        <Box sx={{ width: ['100%', '60%', '60%'], px: 5, py: 2 }}>
          <Box sx={{ pt: 4, pr: 4 }}>
            <Text as="h2" sx={{ mb: 2 }}>
              {card.title}
            </Text>
            <Text as="p" sx={{ fontSize: 1, color: 'grays.4' }}>
              {card.desc}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default CardDetailFrame
