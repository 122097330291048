/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import * as React from 'react'
// import { DialogStateReturn, DialogBackdrop as ReakitDialog.Backdrop, Dialog, DialogDisclosure, useDialogState } from 'reakit'

import {
  DialogStateReturn,
  useDialogState,
  Dialog as ReakitDialog,
  DialogDisclosure,
  DialogBackdrop as ReakitDialogBackdrop,
} from 'reakit/Dialog'

import styled from '@emotion/styled'
import { Portal } from 'reakit/Portal'

const DialogBackdrop = styled(ReakitDialogBackdrop)`
  &[data-leave] {
    opacity: 0;
  }
  &[data-enter] {
    opacity: 1;
  }
  transition: opacity 250ms ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
`

const Dialog = styled(ReakitDialog)`
  &[data-leave] {
    opacity: 0;
  }
  &[data-enter] {
    opacity: 1;
  }
  transition: opacity 250ms ease-in-out;
  position: relative;
  transform: translate(-50%, calc(-50% -50% 0 0));
  border-radius: 0.25rem;
  outline: 0px;
  padding: 24px 32px 24px 40px;
  background-color: red;
  z-index: 999;
`

type ModalProps = {
  isVisible?: boolean
  dialog?: DialogStateReturn
  hide?: any
  label?: string
  className?: string
  disclosure?: React.ReactElement
}

const Modal: React.FC<ModalProps> = ({ hide, isVisible, children, label, className, disclosure, dialog, ...props }) => {
  // const dialog = useDialogState({ animated: true })
  return (
    <Box>
      {disclosure && (
        <DialogDisclosure {...dialog} {...disclosure.props}>
          {disclosureProps => React.cloneElement(disclosure, disclosureProps)}
        </DialogDisclosure>
      )}
      {/* <Portal> */}
      <DialogBackdrop
        {...dialog}
        aria-label="Dialog"
        sx={{
          bg: 'rgba(30,30,30,0.85)',
          display: isVisible ? 'flex' : 'none',
          position: 'fixed',
          zIndex: 100,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Dialog
          // hide={hide}
          {...dialog}
          tabIndex={-1}
          // hideOnEsc
          // hideOnClickOutside
          // preventBodyScroll
          {...props}
          // className={className}
          sx={{
            // top: 0,
            m: 2,
            position: 'relative',
            // left: 0,
            right: 0,
            top: 0,
            mt: ['50%', 0, 0],
            zIndex: 300,
            // ml: '-10%',
            // right: 'auto',
            // bottom: 'auto',
            borderRadius: '8px',
            // mr: '-50%',
            // transform: 'translate(-30% -30%)',
            // height: '10%', // <-- This sets the height
            width: ['80%', '70%', '60%'],
            bg: 'grays.0',
            mx: 'auto',
            // mt: '-30%',
            overflow: 'scroll', // <-- This tells the modal to scrol
          }}
          aria-label={label}
        >
          {children}
        </Dialog>
        {/* </Portal> */}
      </DialogBackdrop>
    </Box>
  )
}

export default Modal
