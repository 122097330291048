/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, jsx, Container, Text, Flex, Image } from 'theme-ui'
import { formatDistanceToNow } from 'date-fns'

export interface FrameGameProps {
  name?: string
  date?: string
  time?: string
  teamb_logo?: string
  teama_logo?: string
  teama?: string
  teamb?: string
  isLink?: boolean
  // time?: string;
}

const FrameGame = ({ date, time, teama, teamb, teamb_logo, teama_logo, isLink = false }: FrameGameProps) => {
  const relTime = time? formatDistanceToNow(new Date(`${time}`), { addSuffix: true }): null;

  return (
    <Box variant="box.plateMedium2" sx={{ color: 'text', px: 2, my: 3, pt: 3}}>
      <Container mb={5} sx={{ px: [5, 0, 0] }}>
        <Box>
          <Text as="p" sx={{ m: 0, textAlign: 'center', fontSize: [0,1,2], fontWeight: 'bold', mb: 1, color: 'grays.8' }}>
            {date}
          </Text>
          <Text as="p" sx={{ m: 0, textAlign: 'center', fontSize: [0,1,1], fontWeight: 500, mb: 4, color: 'grays.5' }}>
            { relTime && relTime }
          </Text>
        </Box>
        <Flex py={[3, 3, 5]} sx={{ borderRadius: 4, alignItems: 'flex-start', border: 'solid 1px #333', '&:hover': { bg: 'grays.1' } }}>          
          <Flex sx={{ width: '42.5%', textAlign: 'right', mr: 2 }}>
            <Flex sx={{ ml: 'auto', alignItems: 'center' }}>
              <Text variant="blockTitleRight" as="h3">
                {teamb}
              </Text>
              <Image src={teamb_logo} sx={{ ml: 2 }} width="48px" />
            </Flex>
          </Flex>
          <Box sx={{ width: '5%', textAlign: 'center' }}>
            <Text as="span" sx={{ fontSize: 0, color: 'grays.5' }}>
              VS
            </Text>
          </Box>
          <Flex sx={{ width: '42.5%', textAlign: 'left', ml: 2 }}>
            <Flex sx={{ mr: 'auto', alignItems: 'center', ml: 2 }}>
              <Image src={teama_logo} sx={{ mr: 2 }} width="48px" />
              <Text variant="blockTitleLeft" as="h3">
                {teama}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default FrameGame
